import React from 'react'
import { css } from 'linaria'
import Img from '../../components/NonStretchedImage'
import Button from 'components/Button'
import SecondaryButton from 'components/Button/Secondary'

import Subheading from 'components/TextElements/Subheading'
import Lead from 'components/TextElements/Lead'
import Section from 'components/Section'
import { colors } from '../../utils/theme'
import rhythm from '../../utils/rhythm'
import breakpoints from '../../utils/tokens/breakpoints'

const center = css`
  text-align: center;
  padding-top: ${rhythm(2)};

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${rhythm(5)};
  }
`

const footerSpacer = css`
  margin-top: ${rhythm(2)};
`

const points = css`
  display: grid;
  grid-template-columns: 100%;
  max-width: 40rem;
  margin: 0 auto ${rhythm(3 / 2)};
  column-gap: ${rhythm(2)};

  @media (min-width: ${breakpoints.md}) {
    grid-template-columns: 1fr 1fr;
    ${''};
  }

  @media (min-width: ${breakpoints.lg}) {
    column-gap: ${rhythm(6)};
    margin-bottom: ${rhythm(2)};
  }
`

const point = css`
  h3 {
    font-size: 2rem;

    @media (min-width: ${breakpoints.md}) {
      font-size: 3.5625rem;
    }
  }

  p {
    color: ${colors.text.lightGray};
  }
`

const cover = css`
  background: #050010;
  border-radius: 16px;
  margin: ${rhythm(2)} 0;

  .gatsby-image-wrapper {
    margin: 0 auto;
  }

  @media (min-width: ${breakpoints.md}) {
    margin: ${rhythm(2)} -${rhythm(2)};
  }
`

const FeaturedCaseStudy = ({ image, itemCount }) => (
  <Section
    className={center}
    header={
      <>
        <Subheading tag="p">Featured JAMstack case study</Subheading>
        <h2>
          Road To a Better And Faster
          <br />
          SEOmonitor Website
        </h2>
        <Lead>
          GatsbyJS, Contentful and Netlify for a faster and more secure
          SEOmonitor website.
        </Lead>
      </>
    }
  >
    <div className={cover}>
      <Img fluid={image.childImageSharp.fluid} alt="Seomonitor" />
    </div>
    <div className={points}>
      <div className={point}>
        <h3>400%</h3>
        <p>performance increase</p>
      </div>
      <div className={point}>
        <h3>25</h3>
        <p>working days to live</p>
      </div>
      {/* <div className={point}>
        <h4>348</h4>
        <p>hours to release mobile app for iOS & Android</p>
      </div> */}
    </div>
    <div>
      <Button to="/blog/seomonitor-case-study/">Read full case study</Button>
      <div className={footerSpacer} />
      <SecondaryButton to="/blog/case-studies/">
        > See {itemCount} more case studies
      </SecondaryButton>
    </div>
  </Section>
)

export default FeaturedCaseStudy
