import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import { css } from 'linaria'
import Img from '../../components/NonStretchedImage'
import cx from '../../utils/cx'
import Subheading from 'components/TextElements/Subheading'
import Lead from 'components/TextElements/Lead'
import Section from 'components/Section'
import Button from 'components/Button'
import breakpoints from '../../utils/tokens/breakpoints'

import { colors, transition } from '../../utils/theme'
import rhythm from '../../utils/rhythm'
import hexToRgb from '../../utils/hexToRgb'

import p22 from '../../assets/img/particles/22.png'
import p28 from '../../assets/img/particles/28.png'
import p7 from '../../assets/img/particles/7.png'
import p30 from '../../assets/img/particles/30.png'

const posts = css`
  display: grid;
  column-gap: ${rhythm(1)};
  row-gap: ${rhythm(1)};
  padding: 0;
  color: #fff;
  position: relative;
  grid-template-areas:
    'mainPost'
    'post4'
    'post1'
    'post2'
    'post3';

  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      'mainPost mainPost'
      'post4 post1'
      'post2 post3';
  }

  ${
    '' /* @media (min-width: ${breakpoints.md}) {
    grid-template-areas:
      'mainPost post4'
      'mainPost post1'
      'post2 post3';
  } */
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 254px);
    grid-template-areas:
      '. mainPost mainPost .'
      '. mainPost mainPost post4'
      'post1 . post2 .'
      '. post3 . .';
    grid-template-areas:
      '. mainPost mainPost .'
      '. mainPost mainPost post4'
      'post1 . post2 post2'
      '. post3 post2 post2';
  }

  @media (min-width: ${breakpoints.xxl}) {
    padding: 0 ${rhythm(3)};
  }
`

const post = `
  position: relative;

  h3 {
    font-size: 1.25rem;
    margin-bottom: ${rhythm(1 / 3)};
  }

  > a {
    border-radius: 8px;
    transition: all ${transition.speed.xl} ${transition.curve.scaleIn};
    backface-visibility: hidden;
    transform: translateZ(0);
    padding: ${rhythm(1)} ${rhythm(1)} ${rhythm(1 / 4)};
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: currentColor;
    position: relative;
    z-index: 2;
    overflow: hidden;
    will-change: transform;

    &:hover {
      transform: scale(1.024) translateZ(0);
    }
  }
`

const mainP = css`
  ${post};
  grid-column: 2 / span 2;
  grid-area: mainPost;

  > a {
    ${'' /* background: ${colors.accent.purple}; */}
    background: #040111;
  }

  &:hover {
    box-shadow: 0 9px 60px 0 rgba(${hexToRgb('#040111')}, 0.35);
  }

  @media (min-width: ${breakpoints.sm}) {
    h3 {
      font-size: 2rem;
      margin-bottom: ${rhythm(1)};
    }

    > a {
      padding: ${rhythm(3 / 2)} ${rhythm(3 / 2)} ${rhythm(1 / 3)};
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-right: ${rhythm(4)};
    margin-top: ${rhythm(4)};
  }
`

const post1 = css`
  ${post};
  grid-area: post1;

  > a {
    ${'' /* background: ${colors.accent.violet}; */}
    background: #ff385e;
    color: #1f0757;
  }

  &:hover {
    box-shadow: 0 9px 60px 0 rgba(${hexToRgb('#ff385e')}, 0.35);
  }
`

const post2 = css`
  ${post};
  grid-area: post2;
  border-radius: 16px;

  > a {
    ${'' /* background: ${colors.accent.dark}; */}
    background: #ff385e;
    color: #1f0757;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-right: ${rhythm(6)};
    margin-bottom: ${rhythm(6)};
  }

  &:hover {
    box-shadow: 0 9px 60px 0 rgba(${hexToRgb('#ff385e')}, 0.35);
  }
`

const post3 = css`
  ${post};
  grid-area: post3;
  border-radius: 16px;

  > a {
    ${'' /* background: ${colors.accent.orange}; */}
    background: #2f0072;
  }

  &:hover {
    box-shadow: 0 9px 60px 0 rgba(${hexToRgb('#2f0072')}, 0.35);
  }
`

const post4 = css`
  ${post};
  grid-area: post4;
  border-radius: 16px;

  @media (min-width: ${breakpoints.md}) {
    ${'' /* margin: ${rhythm(3)} 0 -${rhythm(3)}; */}
  }

  > a {
    background: ${colors.accent.orange};
    color: #62133a;
  }

  &:hover {
    box-shadow: 0 9px 60px 0 rgba(${hexToRgb(colors.accent.orange)}, 0.35);
  }
`

const cta = css`
  text-align: center;
  margin: ${rhythm(3)} 0 ${rhythm(2)};
`

const p22C = css`
  position: absolute;
  max-width: 120px;
  right: 0;
  transform: translate3d(50%, 50%, 0);
  bottom: 0;
  z-index: 0;
  margin: 0;

  @media (max-width: ${breakpoints.lg}) {
    display: none !important;
  }
`

const p30C = css`
  max-width: 172px;

  @media (max-width: ${breakpoints.lg}) {
    display: none !important;
  }
`

const p28C = css`
  position: absolute;
  bottom: 0;
  max-width: 64px;
  left: 0;
  transform: translate3d(-100%, 100%, 0);
  margin: 0;

  @media (max-width: ${breakpoints.lg}) {
    display: none !important;
  }
`

const largeHero = css`
  margin: -${rhythm(3 / 2)} -${rhythm(3 / 2)} auto 0;
  display: block;

  @media (min-width: ${breakpoints.lg}) {
    margin-left: ${rhythm(2)};
  }
`

const LatestPosts = ({ image, particles }) => {
  const data = useStaticQuery(graphql`
    query LatestPostsQuery {
      hero1: file(relativePath: { regex: "/JAMstack-hero-minimal.jpg/" }) {
        childImageSharp {
          fluid(maxHeight: 200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      hero2: file(relativePath: { regex: "/jamstack-for-clients.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 270) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      hero3: file(relativePath: { regex: "/headless-cms-minimal.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 270) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      hero4: file(relativePath: { regex: "/wordpress-alternatives.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 460) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      hero5: file(relativePath: { regex: "/jamstack-pwa-minimal.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 270) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Section
      header={
        <>
          <Subheading>Our stories</Subheading>
          <h2>Latest from blog</h2>
        </>
      }
    >
      <div className={posts}>
        <Img fixed={particles[0].childImageSharp.fixed} className={p30C} />
        <article className={mainP}>
          <Link to="/blog/jamstack/">
            <Img
              fluid={data.hero1.childImageSharp.fluid}
              alt="JAMstack landscape"
              className={largeHero}
              backgroundColor="#040111"
            />
            <h3>JAMstack: The Cornerstone of Modern-day Web Development</h3>
            <p>> Read more</p>
          </Link>
        </article>

        <article className={post4}>
          <Link to="/blog/jamstack-pwa/">
            <Img
              fluid={data.hero5.childImageSharp.fluid}
              alt="Headless CMSes abstract illustration"
              backgroundColor={colors.accent.orange}
              className={css`
                ${{
                  margin: '-1.55rem -1.55rem 0',
                  [`@media (min-width: ${breakpoints.xl})`]: {
                    position: 'absolute !important;',
                    margin: '0 -1.55rem',
                    bottom: '44%',
                    width: '100%'
                  }
                }}
              `}
            />
            <h3>Building a Fast PWA With JAMstack</h3>
            <p>> Read more</p>
          </Link>
        </article>
        <article className={post1}>
          <Link to="/blog/jamstack-for-clients/">
            <Img
              fluid={data.hero2.childImageSharp.fluid}
              alt="JAMstack for Clients"
              backgroundColor="#ff385e"
              className={css`
                ${{
                  margin: '-6.75rem -1.55rem 0'
                }}
              `}
            />
            <h3>JAMstack for Clients</h3>
            <p>> Read more</p>
          </Link>
        </article>
        <article className={post2}>
          <Link to="/blog/wordpress-alternative/">
            <Img
              fluid={data.hero4.childImageSharp.fluid}
              alt="Headless CMSes abstract illustration"
              backgroundColor="#ff385e"
              className={css`
                ${{
                  margin: '-8.5rem -1.55rem 0'
                }}
              `}
            />
            <h3>
              WordPress Alternative: The Pros and Cons of Static Site Generators
            </h3>
            <p>> Read more</p>
          </Link>
          <Img
            fixed={particles[1].childImageSharp.fixed}
            className={p22C}
            style={{ position: 'absolute' }}
          />
        </article>
        <article className={post3}>
          <Link to="/blog/headless-cms/">
            <Img
              fluid={data.hero3.childImageSharp.fluid}
              alt="Headless CMSes abstract illustration"
              backgroundColor="#2f0072"
              className={css`
                ${{
                  margin: '-5rem -1.55rem 0'
                }}
              `}
            />
            <h3>9 Headless CMS Options for Your JAMstack Site</h3>
            <p>> Read more</p>
          </Link>
          <Img
            fixed={particles[2].childImageSharp.fixed}
            className={p28C}
            style={{ position: 'absolute' }}
          />
        </article>
      </div>
      <div className={cta}>
        <Button to="/blog/">Read more stories</Button>
      </div>
    </Section>
  )
}

export default LatestPosts
